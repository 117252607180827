import { add, compareAsc, differenceInMinutes, format, isAfter } from 'date-fns'
import {
  COUNT_AS_OVERLAPPING,
  MIN_SCHEDULE_TIME_IN_MINUTES,
  ROOM_STATUS,
} from 'features/Home/constants/infoConstants'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import { TIME_FORMAT } from 'common/constants/timeFormat'
import { UPDATE_INFO_DRAGGING } from 'features/Home/Book/state/slice/bookSlice'
import { useNotification } from 'app/providers'
import { InfoService } from 'features/Home/services/infoService'
import { UtilService } from 'common/services/utilService'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { useCallback, useMemo, useState } from 'react'
import { useAppDispatch } from 'common/hooks/redux'
import { useInfoManager } from 'features/Home/hooks/useInfoManager'

type UseScheduleCalendarResponse = {
  awaitConfirmationEvent: any
  setAwaitConfirmationEvent: (event: any) => void
  handleScheduleChange: (data: any) => Promise<void>
  proceedToUpdateEvents: () => Promise<void>
  events: IPatientSchedule[]
  handleSelect: (props: any) => void
}

type UseScheduleCalendarProps = {
  dayInterval: { min: string; max: string }
  disabledEverything?: boolean
  disabledIntervals: any[]
  formattedDate: string
  isUpdating: boolean
  rooms: IPatientRoom[]
  schedules: IPatientSchedule[]
  timeZone: string
  scheduleObj: any
  isInPast: boolean
  prepopulateTimeOnClick: number
  createCustomAppointment?: (start: Date, end: Date, resourceId: string) => void
}

export const useScheduleCalendar = ({
  dayInterval,
  disabledEverything = false,
  disabledIntervals,
  formattedDate,
  isUpdating,
  rooms,
  schedules,
  timeZone,
  scheduleObj,
  isInPast,
  prepopulateTimeOnClick,
  createCustomAppointment,
}: UseScheduleCalendarProps): UseScheduleCalendarResponse => {
  const [awaitConfirmationEvent, setAwaitConfirmationEvent] = useState<any>(null)

  const dispatch = useAppDispatch()

  const { setNotification } = useNotification()
  const { updatePatientSchedule } = useInfoManager(formattedDate)

  const isBetween = (number: number, lowerBound: number, upperBound: number): boolean => {
    return number >= lowerBound && number <= upperBound
  }

  const updateDragging = (value: boolean) => {
    dispatch(UPDATE_INFO_DRAGGING(value))
  }

  const handleUpdateEvents = async (adjustedData: any, event: any): Promise<boolean> => {
    return await updatePatientSchedule(adjustedData, event.id)
  }

  const shouldProceedToUpdate = ({
    resourceId,
    start,
    end,
    event,
    room,
    isResizing,
  }: {
    resourceId: string
    start: any
    end: any
    event: any
    room: IPatientRoom
    isResizing?: boolean
    isDrag?: boolean
  }) => {
    const diffInMinutes = differenceInMinutes(end, start)

    if (diffInMinutes < MIN_SCHEDULE_TIME_IN_MINUTES) {
      setNotification({
        title: 'Update schedule error',
        description: `You're schedule can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
        type: ALERT_CONSTANTS.ERROR,
      })

      return false
    }

    const eventsFromRoom = schedules.filter(
      (item) =>
        item.room_id === resourceId &&
        item.id !== event.id &&
        COUNT_AS_OVERLAPPING.includes(item.status.code),
    )

    const { nrOfOverlappingEvents, overlappingEvents } =
      InfoService.getNumberOfOverlappingIntervals({ start, end }, eventsFromRoom, resourceId)

    if (!isBetween(nrOfOverlappingEvents, 1, room.max_patients || 1) && !room?.is_waiting_room) {
      setNotification({
        title: 'Update schedule error',
        description: `You reached the max number of ${
          room.max_patients || 1
        } patients at the same time for this room.`,
        type: ALERT_CONSTANTS.ERROR,
      })
      return false
    }

    const shouldApplyRoomRule = !InfoService.checkIfCurrentDateOverlapDisabledIntervals(
      disabledIntervals,
      room,
      dayInterval,
      timeZone,
    )
    if (
      shouldApplyRoomRule &&
      room &&
      room.status &&
      room.status.code !== ROOM_STATUS.FREE &&
      room.status.code !== ROOM_STATUS.CLEANUP &&
      room.status.code !== ROOM_STATUS.BUSY &&
      !isResizing
    ) {
      return false
    }

    const { start_time: event_start, end_time: event_end, room_id: eventRoom } = event
    const { overlappingEvents: overlappingEventsBeforeUpdate } =
      InfoService.getNumberOfOverlappingIntervals(
        { start: event_start, end: event_end },
        eventsFromRoom,
        eventRoom,
      )

    const shouldShowOverlappingEventsNotification =
      nrOfOverlappingEvents > 1 &&
      !UtilService.arrDeepEqual(overlappingEventsBeforeUpdate, overlappingEvents) &&
      room.value !== rooms[rooms.length - 1].value

    if (shouldShowOverlappingEventsNotification) {
      setAwaitConfirmationEvent({
        start,
        end,
        event,
        room,
      })

      return false
    }

    return true
  }

  const handleScheduleChange = async (data: IPatientSchedule): Promise<void> => {
    updateDragging(false)

    if (isUpdating || disabledEverything) {
      return
    }

    const start = new Date(data.start_time)
    const end = new Date(data.end_time)

    const originalEvent = schedules.find((event) => event.id === data.id)
    const isResizing = originalEvent
      ? originalEvent.start_time === data.start_time || originalEvent.end_time === data.end_time
      : false

    if (isResizing && differenceInMinutes(end, start) === 0) {
      end.setMinutes(end.getMinutes() + 5)
    }

    if (differenceInMinutes(end, start) < MIN_SCHEDULE_TIME_IN_MINUTES) {
      setNotification({
        title: 'Update schedule error',
        description: `Schedule duration can't be less than ${MIN_SCHEDULE_TIME_IN_MINUTES} minutes.`,
        type: ALERT_CONSTANTS.ERROR,
      })
      return
    }

    const [label, value] = data.room_id.split('/')
    const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)
    const shouldUpdateEvent = shouldProceedToUpdate({
      resourceId: data.room_id,
      start,
      end,
      event: data,
      room,
      isResizing,
    })

    if (!shouldUpdateEvent) {
      return
    }

    const { nrOfOverlappingEvents } = InfoService.getNumberOfOverlappingIntervals(
      { start, end },
      schedules,
      data.room_id,
    )

    if (nrOfOverlappingEvents > 1) {
      setAwaitConfirmationEvent({ start, end, event: data, room })
      return
    }

    const adjustedData = {
      room: room?.value,
      start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
      end_time: format(end, TIME_FORMAT.TIME_PICKER_HH_SS),
      temperature_bypass: data.temperature_bypass,
      consultation_staff: data.consultation_staff,
      procedure_code: !data?.procedure?.isCustom ? data?.procedure?.code : null,
      procedure_description: data?.procedure?.description,
    }

    const successUpdate = await handleUpdateEvents(adjustedData, data)
    if (!successUpdate) {
      return
    }
  }

  const proceedToUpdateEvents = useCallback(async () => {
    if (!awaitConfirmationEvent) {
      return
    }

    const { start, end, event, room } = awaitConfirmationEvent
    const adjustedData = {
      room: room?.value,
      start_time: format(start, TIME_FORMAT.TIME_PICKER_HH_SS),
      end_time: format(end, TIME_FORMAT.TIME_PICKER_HH_SS),
      temperature_bypass: event.temperature_bypass,
      consultation_staff: event.consultation_staff,
      procedure_code: !event?.procedure?.isCustom ? event?.procedure?.code : null,
      procedure_description: event?.procedure?.description,
    }

    const successUpdate = await handleUpdateEvents(adjustedData, event)
    if (!successUpdate) {
      return
    }

    scheduleObj?.current?.saveEvent({ ...event })
    setAwaitConfirmationEvent(null)
  }, [awaitConfirmationEvent, handleUpdateEvents])

  const eventsToShow = useMemo(() => {
    if (!schedules) {
      return []
    }

    return schedules.map((event) => {
      return {
        ...event,
        room_id: `${event.room?.label}/${event.room?.value}`,
        start_time: `${event.date} ${event.start_time}`,
        end_time: `${event.date} ${event.end_time}`,
      }
    })
  }, [schedules])

  const handleSelect = useCallback(
    (props: any) => {
      if (disabledEverything || isInPast || !props.data || props.event?.type === 'mouseleave') {
        return
      }

      const [label, value] = props.data.room_id.split('/')
      const room = rooms.find((room: IPatientRoom) => room.label === label && room.value === value)

      if (room?.add_patient === 0) {
        return
      }

      const { start_time, room_id } = props.data
      let { end_time } = props.data

      const diffInMinutes = differenceInMinutes(end_time, start_time)
      const prepPopulateTime = prepopulateTimeOnClick
      const updatedEnd = add(start_time, { minutes: prepPopulateTime })
      const disabledIntervalsForRoom = disabledIntervals
        .filter((interval: any) => interval.resourceId === room_id)
        .sort((a: any, b: any) => compareAsc(a.start, b.start))

      const firstDisabledInterval = disabledIntervalsForRoom.find(
        (interval: any) =>
          isAfter(interval.start, start_time) && isAfter(updatedEnd, interval.start),
      )

      const endDayDate = new Date(`${formattedDate} ${dayInterval.max}`)

      const dateToCheck = firstDisabledInterval?.start || endDayDate

      if (diffInMinutes < prepPopulateTime && !isAfter(updatedEnd, dateToCheck)) {
        end_time = updatedEnd
      } else if (diffInMinutes < prepPopulateTime && isAfter(updatedEnd, dateToCheck)) {
        end_time = dateToCheck
      }

      if (createCustomAppointment) {
        createCustomAppointment(start_time, end_time, room_id)
      }
    },
    [disabledEverything, isInPast, prepopulateTimeOnClick, rooms, disabledIntervals, formattedDate],
  )

  return {
    handleScheduleChange,
    events: eventsToShow,
    awaitConfirmationEvent,
    setAwaitConfirmationEvent,
    proceedToUpdateEvents,
    handleSelect,
  }
}
